
import { computed, defineComponent, reactive, ref } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";

import CSpinner from "@/components/spinner.vue";
import { ObInputSearch } from "@/libs/components";

const VConsultationSpecialty = defineComponent({
  name: "VConsultationSpecialty",
  components: { CSpinner, ObInputSearch },
  setup() {
    useHead({ title: "Especialidades | obmed" });
    const store = useStore();
    const allSpecialty = computed(() => store.state.specialty.list);
    const filteredSpecialtyList = computed(() =>
      allSpecialty.value.filter((item) => item.nm_especialidade?.toLowerCase().includes(search.value?.toLowerCase()))
    );

    const search = ref("");
    const loading = reactive({ list: false });

    async function getAllConsultationSpecialty() {
      loading.list = true;
      await store.dispatch("getAllSpecialty");
      loading.list = false;
    }

    getAllConsultationSpecialty();

    return { allSpecialty, filteredSpecialtyList, search, loading };
  },
});

export default VConsultationSpecialty;
